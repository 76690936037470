import React from "react";
import Layout from "../components/layout/Layout";
import Hero from "../components/layout/Hero";
import { graphql } from "gatsby";
import SEO from "../components/seo/SEO";

function BasicPage({
  data: {
    markdownRemark: {
      frontmatter: { path, title, image },
      html,
      excerpt
    }
  }
}) {
  return (
    <>
      <SEO
        title={title}
        description={excerpt}
        image={`/images/${image}.svg`}
        path={path}
      />
      <Layout>
        <Hero image={image}>{title}</Hero>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          className="my-5 markdown-content lg:w-8/12"
        />
      </Layout>
    </>
  );
}

export default BasicPage;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        path
        image
      }
    }
  }
`;
